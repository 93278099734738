import React from "react";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

const RadioChoice = ({ error, choices, control, name, required, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required && "กรุณากรอกข้อมูลนี้" }}
      render={({ field: { ref, ...rest } }) => {
        return (
          <FormControl component="fieldset" error={!!error}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup {...rest} style={{ paddingLeft: 10 }} row>
              {choices.map((c) => (
                <FormControlLabel
                  value={c.value}
                  control={
                    <Radio
                      sx={{
                        color: "#cfe9ee",
                        "&.Mui-checked": {
                          color: "#efbe25",
                        },
                      }}
                    />
                  }
                  label={<Typography color="#efbe25">{c.label}</Typography>}
                  key={c.value}
                />
              ))}
            </RadioGroup>
            {error && (
              <FormHelperText id="my-helper-text" style={{ color: "#dc3149" }}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default RadioChoice;
