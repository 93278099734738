export default [
  {
    name: "choice.no1",
    type: "choice",
    label: "การวางแผนที่ดี นำมาซึ่งผลลัพท์ที่ดีเสมอ",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 6,
  },
  {
    name: "choice.no2",
    type: "choice",
    label: "เมื่อฉันทำงานเสร็จในแต่ละวัน ฉันสามารถกลับบ้านได้",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 7,
  },
  {
    name: "choice.no3",
    type: "choice",
    label: "ถ้ามีใครทำผิด ฉันก็จะบอกเขา",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 8,
  },
  {
    name: "choice.no4",
    type: "choice",
    label: "คนอื่นมักเรียนรู้จากฉัน มากกว่าที่ฉันเรียนรู้จากพวกเขา",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 9,
  },
  {
    name: "choice.no5",
    type: "choice",
    label: "การพัฒนาตนเองเกิดขึ้นได้เสมอ หากฉันเปิดใจยอมรับแนวความคิดใหม่ๆ",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 10,
  },
  {
    name: "choice.no6",
    type: "choice",
    label:
      "หากมีใครตำหนิในงานที่ฉันทำ ฉันก็คิดว่านั่นคือคำแนะนำที่เป็นประโยชน์",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 11,
  },
  {
    name: "choice.no7",
    type: "choice",
    label: "ฉันมองตัวเองว่า ฉัน...",
    choices: [
      {
        label: "ต้องเป็นที่ 1 หรือ ยอดเยี่ยมที่สุดเสมอ",
        value: "1",
      },
      {
        label: "ยอมรับได้หากได้ลำดับที่ 2 หรือ 3 ในการแข่งขัน",
        value: "2",
      },
      {
        label: "พึงพอใจในสถานการณ์ปัจจุบัน แต่ทุกสิ่งควรดีขึ้นกว่าเดิม",
        value: "3",
      },
      {
        label:
          "เป็นนักจัดหาสิ่งที่ตอบสนองความจำเป็นขึ้นพื้นฐานของชีวิตที่เยี่ยมยอด",
        value: "4",
      },
      {
        label: "ปล่อยทให้ทุกอย่างเป็นไปตามโชคชะตา",
        value: "5",
      },
    ],
    no: 12,
  },
  {
    name: "choice.no8",
    type: "choice",
    label:
      "การกำหนดเป้าหมายและบรรลุเป้าหมายส่วนตัวที่วางไว้เป็นสิ่งที่มีคุณค่าสำหรับฉัน",
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
    no: 13,
  },
  {
    name: "choice.no9",
    type: "choice",
    label:
      "โดยเฉลี่ยนแล้วในเวลา 1 ปี ฉันได้กำหนดเป้าหมายไว้ แล้วทำสำเร็จไปกี่เป้าหมาย",
    choices: [
      {
        label: "0",
        value: "1",
      },
      {
        label: "1-2",
        value: "2",
      },
      {
        label: "3-4",
        value: "3",
      },
      {
        label: "มากกว่า 5",
        value: "4",
      },
      {
        label: "เป้าหมายที่วางไว้ใช้เวลามากกว่า 1 ปีจึงจะสำเร็จ",
        value: "5",
      },
    ],
    no: 14,
  },
];
