import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const ValidationTextField = styled(TextField)({
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#cfe9ee",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#dc3149",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#dc3149",
    borderWidth: 2,
  },
});

const Text = ({ label, line, error, ...rest }) => {
  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant="h6"
        gutterBottom
        color={error ? "#dc3149" : "#efbe25"}
      >
        {label}
      </Typography>
      <ValidationTextField
        multiline={!line}
        rows={4}
        fullWidth
        error={error}
        InputLabelProps={{
          style: { color: "#efbe25" },
        }}
        inputProps={{
          style: { color: "#efbe25", background: "transparent" },
        }}
        {...rest}
      />
    </div>
  );
};

export default Text;
