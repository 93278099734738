import React, { createContext, useState } from "react";

const defaultData = {
  information: null,
  answers: null,
  consent: null,
};

export const DataContext = createContext(defaultData);

export const ContextProvider = ({ children }) => {
  const [consent, setConsent] = useState(null);
  const [information, setInformation] = useState(null);
  const [answers, setAnswers] = useState([]);

  return (
    <DataContext.Provider
      value={{
        consent,
        information,
        answers,
        setInformation,
        setAnswers,
        setConsent,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
