export default [
  {
    name: "choice.no10",
    type: "choice",
    label:
      "หากฉันเริ่มงานขายประกันชีวิตในวันนี้ ฉันมีลูกค้าที่สามารถซื้อประกันชีวิตได้จำนวนกี่คน",
    no: 15,
    choices: [
      {
        label: "น้อยกว่า 10",
        value: "1",
      },
      {
        label: "10 - 50",
        value: "2",
      },
      {
        label: "50 - 100",
        value: "3",
      },
      {
        label: "100 - 150",
        value: "4",
      },
      {
        label: "มากกว่า 150",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no11",
    type: "choice",
    label:
      "หากฉันเริ่มงานขายประกันชีวิตในวันนี้ ครอบครัว เพื่อน หรือ ญาติของฉัน จะซื้อประกันชีวิตจากฉัน คิดเป็นกี่เปอร์เซนต์",
    no: 16,
    choices: [
      {
        label: "1 - 20%",
        value: "1",
      },
      {
        label: "20 - 40%",
        value: "2",
      },
      {
        label: "40 - 60%",
        value: "3",
      },
      {
        label: "60 - 80%",
        value: "4",
      },
      {
        label: "80 - 100%",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no12",
    type: "choice",
    label:
      "กลุ่มคนที่สนิทสนมกับฉันมากที่สุด (ครอบครัว, เพื่อน หรือ ญาติ) ส่วนใหญ่มีมุมมองด้านบวกในอาชีพของฉันในฐานะที่เป็นตัวแทนประกันชีวิต",
    no: 17,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
];
