export default [
  {
    name: "choice.no13",
    type: "choice",
    label: "ฉันวางแผนงานและดำเนินการทุกอย่างตามแผนเสมอ",
    no: 18,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no14",
    type: "choice",
    label:
      "ฉันล้มเลิกอะไรง่ายๆ ตราบใดที่ฉันเผชิญปัญหายุ่งยาก หรือไม่เป็นไปตามที่หวัง",
    no: 19,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no15",
    type: "choice",
    label: "คนที่รู้จักฉันเป็นอย่างดีมักจะกล่าวถึงฉันว่า ฉันเป็นคนที่...",
    no: 20,
    choices: [
      {
        label: "ขับเคลื่อนได้ด้วยเป้าหมายที่ชัดเจนและด้วยความทะเยอทะยาน",
        value: "1",
      },
      {
        label: "กระตือรือร้นและมองหน้าความท้าทายใหม่ๆ",
        value: "2",
      },
      {
        label: "เปิดรับความท้าทายใหม่ๆ แต่จะไม่เดินหน้าเข้าหาสิ่งนั้น",
        value: "3",
      },
      {
        label: "มักนั่งรอให้อะไรบางอย่างเกิดขึ้น",
        value: "4",
      },
      {
        label: "ความเปลี่ยนแปลงไม่มีผลกระทบกับฉัน",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no16",
    type: "choice",
    label: "ฉันรับมือกับความเครียดได้อย่างดี และไม่อารมณ์เสียง่ายๆ",
    no: 21,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no17",
    type: "choice",
    label: "ฉันชอบท้าทายตัวเองอยู่เป็นประจำ ด้วยแรงกระตุ้นใหม่ๆ",
    no: 22,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no18",
    type: "choice",
    label: "เมื่อไม่มีคำแนะนำ ฉันรู้สึกไร้ทิศทาง และไม่มัั่นใจในตัวเอง",
    no: 23,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
];
