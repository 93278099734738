import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TypographyMUI from "@mui/material/Typography";
import NextButton from "components/Button";
import RadioChoice from "./Choice";
import TextFieldNormal from "./Text";
import { DataContext } from "../../dataContext";

function Typography(props) {
  return <TypographyMUI variant="h6" color="#efbe25" {...props} />;
}

function InitForm({ onSubmitForm, onGoBack }) {
  const { setInformation, information, consent } = useContext(DataContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: information || {},
  });
  const onSubmit = (data) => {
    setInformation(data);
    onSubmitForm();
  };
  if (!consent) {
    onGoBack();
  }
  return (
    <Container maxWidth="md" style={{ paddingTop: 30, paddingBottom: 30 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 40 }}>
            <Typography variant="h6" color="#efbe25">
              ข้อมูลส่วนบุคคล <span style={{ color: "#dc3149" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldNormal
              name="name"
              control={control}
              required
              error={errors.name}
              label="ชื่อ นามสกุล:"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldNormal
              name="tel"
              control={control}
              required
              error={errors.tel}
              label="หมายเลขโทรศัพท์:"
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <TextFieldNormal
              name="age"
              control={control}
              required
              error={errors.age}
              label="อายุ:"
              type="number"
            />
          </Grid>
          <Grid item xs={9} sm={9}>
            <TextFieldNormal
              name="email"
              control={control}
              required
              error={errors.email}
              label="Email:"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>
              สถานภาพสมรส <span style={{ color: "#dc3149" }}>*</span>
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <RadioChoice
              name="status"
              control={control}
              required
              error={errors.status}
              multiline
              choices={[
                { value: "single", label: "โสด" },
                {
                  value: "singleBut",
                  label: "โสดแต่มีผู้อุปการะ(เช่นบิดา มารดา)",
                },
                { value: "married", label: "สมรส" },
                { value: "marriedAndHaveChildren", label: "สมรสและมีบุตร" },
                { value: "other", label: "อื่นๆ" },
              ]}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 40 }}>
            <Typography variant="h6">
              ที่พักอาศัย <span style={{ color: "#dc3149" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldNormal
              name="address"
              control={control}
              required
              error={errors.address}
              minRows={4}
              multiline
              label="ที่อยู่:"
            />
          </Grid>
          <Grid item xs={12}>
            <RadioChoice
              name="addressOwner"
              control={control}
              required
              error={errors.addressOwner}
              minRows={4}
              multiline
              choices={[
                { value: "another", label: "เป็นของบุคคลอื่น(เช่นบิดา มารดา)" },
                {
                  value: "rent",
                  label: "เช่าอาศัย",
                },
                { value: "owner", label: "เป็นเจ้าของ" },
                { value: "other", label: "อื่นๆ" },
              ]}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 40, marginBottom: 10 }}>
            <Typography variant="h6">
              รายละเอียดที่ทำงาน (เท่าที่ให้ข้อมูลได้)
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>นายจ้างปัจจุบัน:</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldNormal
              name="currentJob.org"
              control={control}
              error={errors.currentJob?.org}
              label="ชื่อบริษัท"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldNormal
              name="currentJob.leader"
              control={control}
              error={errors.currentJob?.leader}
              label="ชื่อหัวหน้างาน"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldNormal
              name="currentJob.tel"
              control={control}
              error={errors.currentJob?.org}
              label="เบอร์โทรศัพท์ที่ทำงาน"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>นายจ้างก่อนหน้า:</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldNormal
              name="lastJob.org"
              control={control}
              error={errors.lastJob?.org}
              label="ชื่อบริษัท"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldNormal
              name="lastJob.leader"
              control={control}
              error={errors.lastJob?.leader}
              label="ชื่อหัวหน้างาน"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextFieldNormal
              name="lastJob.tel"
              control={control}
              error={errors.lastJob?.tel}
              label="เบอร์โทรศัพท์ที่ทำงาน"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 40, marginBottom: 10 }}>
            <Typography variant="h6">
              ความสนใจ/งานอดิเรก<span style={{ color: "#dc3149" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldNormal
              name="hobby"
              control={control}
              required
              error={errors.hobby}
              minRows={4}
              multiline
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 40, marginBottom: 10 }}>
            <Typography variant="h6">
              บุคคลที่คุณให้ความเคารพมากที่สุดคือใคร? เพราะอะไร?{" "}
              <span style={{ color: "#dc3149" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldNormal
              name="respect"
              control={control}
              required
              error={errors.respect}
              minRows={4}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <NextButton type="submit">ต่อไป</NextButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default InitForm;
