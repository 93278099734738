import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import get from "lodash/get";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import question from "config/question";
import Text from "components/mobile/Text";
import Choice from "components/mobile/Choice";
import Button from "components/Button";
import Loading from "images/loading.gif";
import { DataContext } from "../../dataContext";

const Slider = ({ onGoBack, onGoFwd }) => {
  const { information } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });
  const [active, setActive] = useState(0);

  const goPrev = () => {
    if (active > 0) {
      setActive(active - 1);
    } else {
      onGoBack();
    }
  };
  const goNext = () => {
    if (active < question.length - 1) {
      setActive(active + 1);
    }
  };
  const percentage = -100 * active + "%";
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/send-answer`, {
        information,
        answers: data,
      });
      onGoFwd();
    } catch (error) {
      console.log(error);
    }
  };

  const onError = (errors, e) => {
    if (errors?.toEmail) {
      return setActive(0);
    }
    if (errors?.text) {
      const firstQuestion = Object.keys(errors.text)[0];
      const questionIndex = question.findIndex(
        (q) => q.name === `text.${firstQuestion}`
      );
      if (questionIndex !== -1) {
        return setActive(questionIndex);
      }
    }
    if (errors?.choice) {
      const firstQuestion = Object.keys(errors.choice)[0];
      const questionIndex = question.findIndex(
        (q) => q.name === `choice.${firstQuestion}`
      );
      if (questionIndex !== -1) {
        return setActive(questionIndex);
      }
    }
    console.log(errors);
  };

  if (!information) {
    onGoBack();
  }
  const loadingStyle = loading ? { filter: "blur(10px)" } : {};
  return (
    <Container
      maxWidth="md"
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        style={{ ...loadingStyle }}
      >
        <div
          className="slides"
          style={{ transform: `translate3d(0, ${percentage}, 0)` }}
        >
          {question.map((q, index) => {
            let QuestionComponent = Text;
            switch (q.type) {
              case "text":
                QuestionComponent = Text;
                break;
              case "choice":
                QuestionComponent = Choice;
                break;
              default:
                break;
            }
            const questionLength = question.length;
            return (
              <div className="slide" key={q.name}>
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 20,
                    left: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div onClick={goPrev}>
                    <Typography color="#efbe25">{"< ย้อนกลับ"}</Typography>
                  </div>
                  {q.no && (
                    <Typography color="#efbe25">
                      ข้อที่ {q.no} จาก {questionLength - 1}
                    </Typography>
                  )}
                </div>
                <Controller
                  key={q.name}
                  name={q.name}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...rest } }) => {
                    return (
                      <QuestionComponent
                        line={q.line}
                        choices={q.choices}
                        label={q.label}
                        // error={!!errors[q.name]}
                        error={!!get(errors, q.name)}
                        {...rest}
                      />
                    );
                  }}
                />
                <div
                  style={{ width: "100%", textAlign: "right", marginTop: 20 }}
                >
                  <Button
                    type={index + 1 === question.length ? "submit" : "button"}
                    onClick={goNext}
                    variant="contained"
                  >
                    {index + 1 === question.length ? "ส่งคำตอบ" : "ข้อต่อไป"}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </form>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Loading} alt="loading" />
        </div>
      )}
    </Container>
  );
};

export default Slider;
