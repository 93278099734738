import React from "react";
import success from "images/success.jpeg";
import Typography from "@mui/material/Typography";

function Success() {
  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 360, textAlign: "center" }}>
        <img
          src={success}
          alt="success"
          width="100%"
          style={{ borderRadius: "8px" }}
        />
        <Typography color="#efbe25" textAlign="center">
          แบบทดสอบได้ส่งหาผู้แนะนำของคุณแล้ว
          <br />
          กรุณารอการติดต่อกลับจากผู้แนะนำของท่าน
        </Typography>
      </div>
    </div>
  );
}

export default Success;
