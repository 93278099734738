export default [
  {
    name: "choice.no25",
    type: "choice",
    label:
      "ความคุ้มครองของการทำประกันชีวิตนั้น ให้ความคุ้มครองเฉพาะหัวหน้าครอบครัว หรือ คนที่หารายได้หลักให้ครอบครัวเท่านั้น",
    no: 30,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no26",
    type: "choice",
    label: "ฉันเชื่อว่าคนส่วนใหญ่จะปฏิเสธการประกันชีวิต",
    no: 31,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no27",
    type: "choice",
    label:
      "ฉันคิดว่าเป็นการง่ายกว่าที่จะขายประกันชีวิตให้กับคนแปลกหน้า มากกว่าขายให้คนในครอบครัว เพื่อน หรือ ญาติของฉัน",
    no: 32,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no28",
    type: "choice",
    label: "ฉันเชื่อว่าเราควรเริ่มวางแผนการเกษียนอายุตั้งแต่อายุ",
    no: 33,
    choices: [
      {
        label: "25 ถึง 35",
        value: "1",
      },
      {
        label: "35 ถึง 40",
        value: "2",
      },
      {
        label: "40 ถึง 45",
        value: "3",
      },
      {
        label: "45 ถึง 50",
        value: "4",
      },
      {
        label: "50 ถึง 55",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no29",
    type: "choice",
    label:
      "เมื่อฉันเริ่มวางแผนทางการเงิน สิ่งที่สำคัญที่สุดคือ จะต้อง รวบรวมข้อมูลทั้งหมดของลูกค้าก่อนที่จะให้คำแนะนำได้",
    no: 34,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no30",
    type: "choice",
    label: `"การวางแผนทางการเงินมีไว้สำหรับคนที่มีความมั่งคั่งเท่านั้น" ฉันคิดอย่างไรต่อข้อความนี้`,
    no: 35,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
];
