import React from "react";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

const ValidationTextField = styled(TextField)({
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#cfe9ee",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#dc3149",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
    borderWidth: 2,
  },
  ".MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#dc3149",
    borderWidth: 2,
  },
});

const TextFieldNormal = ({
  name,
  control,
  required,
  label,
  error,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required && "กรุณากรอกข้อมูลนี้" }}
      render={({ field: { ref, ...rest } }) => {
        return (
          <FormControl fullWidth>
            <ValidationTextField
              fullWidth
              label={label}
              InputLabelProps={{
                style: { color: "#efbe25" },
              }}
              inputProps={{
                style: { color: "#efbe25", background: "transparent" },
              }}
              {...rest}
              error={!!error}
              {...props}
            />
            {error && (
              <FormHelperText id="my-helper-text" style={{ color: "#dc3149" }}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default TextFieldNormal;
