export default [
  {
    name: "choice.no19",
    type: "choice",
    label: "ฉันสนุกสนานในการมีคนรู้จักในวงกว้าง",
    no: 24,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no20",
    type: "choice",
    label: "ฉันมองตัวฉันเองว่า ตัวฉันเป็นคนที่ ...",
    no: 25,
    choices: [
      {
        label: "ชอบออกสังคมและเข้ากับคนง่าย",
        value: "1",
      },
      {
        label: "เป็นมิตร และ ช่างพูด ช่างคุย",
        value: "2",
      },
      {
        label: "มีส่วนร่วมในการพูดคุยด้วย",
        value: "3",
      },
      {
        label: "สุขุม และ หนักแน่น",
        value: "4",
      },
      {
        label: "ทำงานได้อย่างยอดเยี่ยมเมื่ออยู่ลำพัง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no21",
    type: "choice",
    label:
      "ในการสนทนากับคนอื่นๆ ในกลุ่ม ฉันจะมีส่วนร่วมในการสนทนา และสามารถสนทนาได้ยาวนาน",
    no: 26,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no22",
    type: "choice",
    label: "โดยปกติแล้ว ฉันรอให้คนที่ฉันไม่รู้จัก เริ่มมาพูดกับฉันก่อน",
    no: 27,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no23",
    type: "choice",
    label: `"ไม่มีใครสามารถทำให้ฉันรู้สึกด้อยกว่าได้ หากฉันไม่ยินยอม" ฉันรู้สึกอย่างไรกับข้อความนี้`,
    no: 28,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
  {
    name: "choice.no24",
    type: "choice",
    label:
      "ในสังคมใหม่ๆ ฉันมักรู้สึกวิตกกังวล หากฉันไม่รู้ว่าที่นั่นคาดหวังอะไรจากฉัน",
    no: 29,
    choices: [
      {
        label: "เห็นด้วยอย่างยิ่ง",
        value: "1",
      },
      {
        label: "เห็นด้วย",
        value: "2",
      },
      {
        label: "ไม่มีความคิดเห็น",
        value: "3",
      },
      {
        label: "ไม่เห็นด้วย",
        value: "4",
      },
      {
        label: "ไม่เห็นด้วยอย่างยิ่ง",
        value: "5",
      },
    ],
  },
];
