import text from "./text";
import personality from "./personality";
import marketing from "./marketing";
import inspiration from "./inspiration";
import connection from "./connection";
import believe from "./believe";

const questions = [
  {
    name: "toEmail",
    type: "text",
    label: "กรุณากรอก Email ของผู้แนะนำ",
    line: true,
  },
  ...text,
  ...personality,
  ...marketing,
  ...inspiration,
  ...connection,
  ...believe,
];

export default questions;
