export default [
  {
    name: "text.no1",
    type: "text",
    label:
      "ในการทำงานที่ผ่านมา คุณเคยตัดสินใจการตัดสินใจเรื่องใดที่คุณคิดว่ายากที่สุด ? และส่งผลอย่างไร ?",
    no: 1,
  },
  {
    name: "text.no2",
    type: "text",
    label:
      "คุณเคยรับมือกับสิ่งที่เป็นความลับมาก่อนบ้างหรือไม่ ? แล้วคุณรับมือกับเรื่องนั้นอย่างไร",
    no: 2,
  },
  {
    name: "text.no3",
    type: "text",
    label:
      "ในการทำงานที่ผ่านมา คุณเคยถูกทดสอบความซื่อสัตย์ สุจริต บ้างหรือไม่ ? แล้วคุณรับมืออย่างไร",
    no: 3,
  },
  {
    name: "text.no4",
    type: "text",
    label:
      "คนทุกคนย่อมต้องได้รับการคุ้มครองดูแลผลประโยชน์ส่วนตัว คุณจะมีวิธีดูแลผลประโยชน์ให้ลูกค้าอย่างไร ?",
    no: 4,
  },
  {
    name: "text.no5",
    type: "text",
    label:
      "คุณพอเล่าให้ฟังได้ไหมว่า คุณจะสร้างความสัมพันธ์กับลูกค้าในระยะยาวได้อย่างไรบ้าง ?",
    no: 5,
  },
];
