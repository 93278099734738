import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const NextButton = styled(Button)({
  background: "#efbe25",
  color: "white",
  "&:hover": {
    backgroundColor: "#f86200",
    borderColor: "#f86200",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#f86200",
    borderColor: "#f86200",
  },
  "&:disabled": {
    boxShadow: "none",
    backgroundColor: "gray",
    borderColor: "#f86200",
  },
});

export default NextButton;
