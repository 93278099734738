import { useState, useEffect } from "react";
import Questionnaire from "containers/Questionnaire";
import InitForm from "containers/InitForm";
import Success from "containers/success";
import Consent from "containers/consent";
import Loading from "images/loading.gif";

function App() {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmitForm = () => {
    setStep(2);
  };
  const onGoToInformation = () => {
    setStep(1);
  };
  const onGoToSuccess = () => {
    setStep(3);
  };
  const onGoToConsent = () => {
    setStep(0);
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <InitForm onSubmitForm={onSubmitForm} onGoBack={onGoToConsent} />
        );
      case 2:
        return (
          <Questionnaire onGoBack={onGoToInformation} onGoFwd={onGoToSuccess} />
        );
      case 3:
        return <Success />;
      default:
        return <Consent onGoFwd={onGoToInformation} />;
    }
  };
  const loadingStyle = loading ? { filter: "blur(10px)" } : {};
  return (
    <div
      style={{
        background: "#003781",
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        padding: 0,
        margin: 0,
      }}
    >
      <div className="elementToFadeInAndOut" style={{ ...loadingStyle }}>
        {renderStep()}
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Loading} alt="loading" />
        </div>
      )}
    </div>
  );
}

export default App;
