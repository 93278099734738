import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import NextButton from "components/Button";
import { DataContext } from "../../dataContext";

function Consent({ onGoFwd }) {
  const { setConsent, consent } = useContext(DataContext);
  const handleChangeCheckBox = (e) => {
    setConsent(e.target.checked);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            maxWidth: 800,
            width: "100%",
            overflow: "auto",
            maxHeight: "80vh",
            textAlign: "center",
            background: "white",
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Typography color="#efbe25" variant="h5">
            หนังสือยินยอมในการเก็บและเปิดเผยข้อมูลส่วนบุคคล
          </Typography>
          <Typography color="#efbe25" variant="h6">
            (สำหรับผู้ทำแบบทดสอบ)
          </Typography>
          <Typography color="#efbe25" textAlign="left">
            โดยหนังสือฉบับนี้ ข้าพเจ้า ยินยอมให้ ผู้แนะนำ ดำเนินการจัดเก็บ
            รวมรวม เก็บรักษา ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            เพื่อใช้ในการบริหารงานบุคคลในหน่วยงานของผู้แนะนำ
            และให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล โดยผู้แนะนำ
            จะเก็บรักษาข้อมูลดังกล่าวไว้เป็นความลับ
            และใช้ในวัตถุประสงค์ดังต่อไปนี้
          </Typography>
          <Typography color="#efbe25" textAlign="left">
            <ol>
              <li>
                ข้อมูลส่วนบุคคลของท่านที่บริษัทฯ ได้รับมา เช่น
                ข้อมูลในการกรอกฟอร์มแบบทดสอบกับทางผู้แนะนำ ได้แก่ ชื่อ-นามสกุล,
                ชื่อเล่น, ที่อยู่ที่สามารถติดต่อได้, เบอร์โทรศัพท์, อีเมล์
                (E-mail), วันเดือนปีเกิด, วุฒิการศึกษา, สัญชาติ, เชื้อชาติ,
                ศาสนา หรือเอกสารใด ๆที่เกี่ยวกับการทำแบบทดสอบ เป็นต้น ผู้แนะนำ
                จะใช้ข้อมูลสำหรับจัดทำเป็นประวัติตัวแทนตามสังกัด
                เป็นทะเบียนข้อมูล ของผู้ทำแบบทดสอบ
                โดยจะมีการจัดเก็บเป็นไฟล์ข้อมูลอิเล็กทรอนิกส์
                และมีการจัดเก็บเป็นข้อมูลแยกตามรายบุคคล
              </li>
              <li>
                ผู้แนะนำ จะดำเนินการเก็บรวบรวม ใช้ เปิดเผย
                ภายใต้วัตถุประสงค์ของการแนะนำการทำงาน ในการประมวลผล
                พิจารณาลักษณะงานที่เหมาะสม, เพื่อติดต่อกับท่าน,
                เพื่อประโยชน์โดยชอบด้วยกฎหมาย หรือ เพื่อปฏิบัติตามกฎหมายหรือ
                ข้อยกเว้นตามกฎหมาย
                ไม่ว่าตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลหรือกฎหมายใด ๆ{" "}
              </li>
              <li>
                สิทธิของเจ้าของข้อมูล ท่านมีสิทธิในการแก้ไขข้อมูลส่วนบุคคล,
                ลบหรือทำลาย, เข้าถึงข้อมูลส่วนบุคคล, โอน ข้อมูลส่วนบุคคล,
                คัดค้านการเก็บรวบรวม ใช้ เปิดเผย
                หรือระงับการใช้ข้อมูลส่วนบุคคลของท่าน โดยแจ้งความ
                ประสงค์มายังผู้แนะนำ
                ท่านสามารถเลือกว่าจะให้ข้อมูลส่วนบุคคลแก่ผู้แนะนำ หรือไม่ก็ได้
                แต่อย่างไรก็ตาม โปรดทราบว่าหากท่าน
                ไม่ให้ข้อมูลส่วนบุคคลแก่ผู้แนะนำ อาจมีบางบริการที่ผู้แนะนำ
                ไม่สามารถให้บริการแก่ท่านได้หากปราศจากข้อมูล ส่วนบุคคลของท่าน
                ในกรณีที่ผู้แนะนำ มีการเก็บรวบรวม จัดเก็บ
                ใช้และเปิดเผยข้อมูลส่วนบุคคล เพื่อดำเนินการ อื่น
                นอกเหนือจากวัตถุประสงค์ตามที่ได้ระบุไว้ ผู้แนะนำ
                จะแจ้งวัตถุประสงค์ใหม่ให้ท่านทราบ และได้รับความ ยินยอมก่อน
                ทั้งนี้
                ท่านสามารถอ่านรายละเอียดเพิ่มเติมเกี่ยวกับข้อมูลส่วนบุคคลได้ที่
                นโยบายคุ้มครองข้อมูลส่วนบุคคล
                เพื่อเป็นหลักฐานแสดงความยินยอมตามหนังสือฉบับนี้{" "}
              </li>
            </ol>
          </Typography>
          <FormControlLabel
            style={{ marginTop: 30 }}
            control={
              <Checkbox
                onChange={handleChangeCheckBox}
                sx={{
                  color: "#cfe9ee",
                  "&.Mui-checked": {
                    color: "#efbe25",
                  },
                }}
                name="consent"
              />
            }
            label={
              <Typography color="#efbe25">ข้าพเจ้าให้ความยินยอม</Typography>
            }
          />
        </div>
      </div>
      <div style={{ margin: "auto", maxWidth: 800, marginTop: 10 }}>
        <NextButton disabled={!consent} onClick={onGoFwd} fullWidth>
          ต่อไป
        </NextButton>
      </div>
    </>
  );
}

export default Consent;
