import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function ControlledRadioButtonsGroup({
  label,
  error,
  choices,
  line,
  ...rest
}) {
  return (
    <div style={{ width: "100%" }}>
      <FormControl
        component="fieldset"
        error={error}
        style={{ maxWidth: "100%" }}
      >
        <FormLabel component="legend">
          <Typography color="#efbe25" variant="h6">
            {label}
          </Typography>
        </FormLabel>
        <RadioGroup {...rest} style={{ paddingLeft: 10 }}>
          {choices.map((c) => (
            <FormControlLabel
              key={c.value}
              value={c.value}
              control={
                <Radio
                  sx={{
                    color: "#cfe9ee",
                    "&.Mui-checked": {
                      color: "#efbe25",
                    },
                  }}
                />
              }
              label={<Typography color="#efbe25">{c.label}</Typography>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
